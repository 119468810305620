
import axios from 'axios'
import {  dashboardURL } from '../config/api'
import { encryptStorage } from 'components/EncryptedStorage/encryptedStorage';



export const getDashboardInformation = async () => 
{

   
    const userInfo =  await encryptStorage.getItem('userInfo');
    const token = "Bearer " + userInfo.userToken;
    const user = userInfo.userName;
    const userNameToSend = {email: user}

   
       return axios.post(dashboardURL, userNameToSend, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
}



