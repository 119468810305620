/*eslint-disable*/
import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
//import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";

// material ui icons
import Close from "@material-ui/icons/Close";


import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
//import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { addUserAction, upadateUserAction } from "action/userAction";

// style for this view
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { useHistory } from "react-router";
import { connect } from "react-redux";

import * as service from '../../services/userService'
import * as notif from "components/Notification/notification"
import { useMemo } from "react";
import * as serviceRole from '../../services/roleService'
import { useNavigate, useParams } from "react-router-dom-v5-compat";

const useStyles = makeStyles(styles);
const useStylesExtended = makeStyles(stylesExtended);


const UserUpdate = (props) => 
{

 // const history = useNavigate();


  const [paramClientId, setParamClientId] = useState('')     
  const [paramUserId, setParamUserId] = useState(0)     
  

  const [listRoleState, setListRoleState] = useState()
  const [roleSelectElem, setRoleSelectElem] = useState()
  const [roleSelectFinal, setRoleSelectFinal] = useState("")
  

  const [openNotif, setOpenNotif] = useState(false)

  // type validation
 
  const [emailUser, setEmailUser] = React.useState("");
  const [emailUserState, setEmailUserState] = React.useState("");

  const [nomUser, setNomUser] = React.useState("");
  const [nomUserState, setNomUserState] = React.useState("");

  const [prenomUser, setPrenomUser] = React.useState("");
  const [prenomUserState, setPrenomUserState] = React.useState("");


  const [activeUserSelect, setActiveUserSelect] = React.useState(true);

  const [registerPassword, setregisterPassword] = React.useState("");
  const [registerPasswordState, setregisterPasswordState] = React.useState("");
  const [registerConfirmPassword, setregisterConfirmPassword] = React.useState(
    ""
  );
  const [
    registerConfirmPasswordState,
    setregisterConfirmPasswordState
  ] = React.useState("");

  
  const  paramURLClientID   = useParams().id;
  const paramURLUserID = useParams().userId 
     
  useEffect(() => {
    setParamClientId(paramURLClientID);
    setParamUserId(paramURLUserID || 0);
  }, [])




    const handleRole = event => 
    {
      setRoleSelectFinal(event.target.value); 
    }


const  selectRole = async () => 
{
   const listRole = await serviceRole.getRoles();
   setListRoleState(listRole);
 }

 useEffect(() => {
  selectRole();


  
 }, [])



    const handleActiveUser = event => 
    {
      setActiveUserSelect(event.target.value);
    };

    useEffect(()=> {

      if (openNotif === true)
      {
        if (props.loadUserFromSaga.loading === false)
        {
          let msg;
            let title;
            
          if (props.loadUserFromSaga.error.length > 0)
          {
            if (paramUserId)
            {
              title = "Echeque Mise a jour user"
              msg = "Mise a jours user echoué";
            }
            else
            {
              title = "Echeque ajout user"
              msg = "La user " + nomUser + " n a pas ete ajouter";
            }
            notif.showFailedNotification(title, msg)
            
          }
          else
          {
            if (paramUserId)
            {
              title = "Mise a jour user"
              msg = "La user " + nomUser + "  a ete mise a jours";
            }
            else
            {
              title = "Ajout  user"
              msg = "La user " + nomUser + " a ete ajouter";
            }
            notif.showSuccessNotification(title, msg)
          }
          setOpenNotif(false)
      }
    }
      
    }, [props.loadUserFromSaga])

    useEffect(() => 
    {
      if (paramUserId)
      {
        
        const data = {
          'userId' : paramUserId
        }

        
        

        service.getItemUser(data).then(
          (res) => {
         //   // console.log(" RES = ",res)
            
            
            setEmailUser(res.email)
            if (verifyLength(res.email, 0))
            {
              setEmailUserState("success");
            }
            setNomUser(res.nom)
            if (verifyLength(res.nom, 0))
            {
              setNomUserState("success");
            }

           
            setPrenomUser(res.prenom)
            if (verifyLength(res.prenom, 0))
            {
              setPrenomUserState("success");
            }
            
            const role = res.roles;
            if (role.length > 0)
            {
              setRoleSelectFinal(role[0].role)
              
            }

           
            // console.log(res)
           /* 
            
            
            
            
            //setactivation(res.type)
            setactivationState("success");
*/
          }).catch(
            (error) => {
              // console.log('error = ',error)
              // console.error("ERROR", error)
            }
          )
      }
      
    }, [paramUserId])
       
   

       
        const successDelete = () => {
            setAlert(
              <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Deleted!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
              >
                Your imaginary file has been deleted.
              </SweetAlert>
            );
          };
          const cancelDetele = () => {
            setAlert(
              <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Cancelled"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
              >
                Your imaginary file is safe :)
              </SweetAlert>
            );
          };
          const hideAlert = () => {
            setAlert(null);
          };
        
        // function that returns true if value is email, false otherwise
        const verifyEmail = value => {
          var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (emailRex.test(value)) {
            return true;
          }
          return false;
        };
      
        
        // function that verifies if a string has a given length or not
        const verifyLength = (value, length) => {
          if (value.length > length) {
            return true;
          }
          return false;
        };
        // function that verifies if value contains only numbers
        const verifyNumber = value => {
          var numberRex = new RegExp("^[0-9]+$");
          if (numberRex.test(value)) {
            return true;
          }
          return false;
        };
       
        
        const typeClick = () => 
        {
            let error = false;

            if (!paramUserId)
            if (registerPasswordState === "") 
            {
              error = true;
              setregisterPasswordState("error");
            }
            if (!paramUserId)
            if (registerConfirmPasswordState === "") 
            {
              error = true;
              setregisterConfirmPasswordState("error");
            }

          if (emailUser === "") 
          {
            error = true;
            setEmailUserState("error");
          }

          if (nomUser === "") 
          {
            error = true;
            setNomUserState("error");
          }

          if (prenomUser === "") 
          {
            error = true;
            setPrenomUserState("error");
          }

          

          

          if (activeUserSelect === "") 
          {
            error = true;
            
          }

          if (registerPassword !== registerConfirmPassword)
          {
            error = true;
            setregisterConfirmPasswordState("error");
          }
/*
{
  "identifiantUser": 1,
  
  "emailUser": "email@ccc.com",
  "etatUser": "1",
  
  "nomUser": "taphus",
  "prenom": "555555",
  "telephone2": "555555",
  "type": "333333"
}
*/

          const data = {
            "id": paramUserId,
            "email": emailUser,
            "password": registerPassword,
            "prenom" : prenomUser,
            "nom" : nomUser,
            //"mobileNumber" : numeroUser,
            "cni": "XXX",
            "clientId": paramClientId,
             "roles" : [
                 {"role": roleSelectFinal}
             ]
            
          }
          

          // console.log('******* DATA ******  = ', data)

          if (error === false)
          {
            
            setOpenNotif(true)
            if (paramUserId)
            {
              props.userUpdateDispatch(data);
              
            }
            else
            {
              props.userAddDispatch(data);
              
              
            }
            
            
            //history('/')
            
          }
          
        };


        const classes = useStyles();
        const classesExtended = useStylesExtended();



 useEffect(() => {
  if (listRoleState !== undefined)
  {
  const roles = listRoleState.map((role) => {
    
    return (
      <MenuItem
      key={ role.id}
      classes={{
                      root: classesExtended.selectMenuItem,
                      selected: classesExtended.selectMenuItemSelected
                    }}
                    selected={( roleSelectFinal === role.role) ? true : false }
                    value={ role.role}
                  >
                    { role.role}
    </MenuItem>
    )
  })

  const roleElem = () => {
    return (
      <Select
                  MenuProps={{
                    className: classesExtended.selectMenu
                  }}
                  classes={{
                    select: classesExtended.select
                  }}
                  value={ roleSelectFinal}
                  onChange={handleRole}
                  inputProps={{
                    name: "roleItem",
                    id: "roleItem"
                  }}
                >

                  
                  <MenuItem
                    disabled
                    classes={{
                      root: classesExtended.selectMenuItem
                    }}
                  >
                    role
                  </MenuItem>

                  
                  { roles}
                  
                  
                  
                  
                  
                </Select>
    )
  }

  setRoleSelectElem( roleElem)
}
 }, [listRoleState, roleSelectFinal])


        /*
        
etatUser: "1"
prenom: "555555"
telephone2: "555555"
type: "333333"
        */

const passwordJsxContainer = () => {

  if (!paramUserId)
  return (
    <>
    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Password
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={7}>
                        <CustomInput
                          success={registerPasswordState === "success"}
                          error={registerPasswordState === "error"}
                          labelText="Password *"
                          id="registerpassword"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              if (verifyLength(event.target.value, 1)) {
                                setregisterPasswordState("success");
                              } else {
                                setregisterPasswordState("error");
                              }
                              setregisterPassword(event.target.value);
                            },
                            type: "password",
                            autoComplete: "off"
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelLeftHorizontal}>
                          <code>email</code>
                        </FormLabel>
                      </GridItem>
                    </GridContainer>


                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Confirmation Password
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={7}>
                      <CustomInput
                          success={registerConfirmPasswordState === "success"}
                          error={registerConfirmPasswordState === "error"}
                          labelText="Confirm Password *"
                          id="registerconfirmpassword"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              if (registerPassword === event.target.value) {
                                setregisterConfirmPasswordState("success");
                              } else {
                                setregisterConfirmPasswordState("error");
                              }
                              setregisterConfirmPassword(event.target.value);
                            },
                            type: "password",
                            autoComplete: "off"
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelLeftHorizontal}>
                          <code>password</code>
                        </FormLabel>
                      </GridItem>
                    </GridContainer>
                    </>
  )

}
        
        return (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" text>
                  <CardText color="info">
                    <h4 className={classes.cardTitle}>Type Validation</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <form>
                    

                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Email
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={7}>
                        <CustomInput
                          success={emailUserState === "success"}
                          error={emailUserState === "error"}
                          id="emailUser"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              if (!paramUserId)
                              setEmailUser(event.target.value);
                              
                              if (verifyLength(event.target.value, 0)) {
                                setEmailUserState("success");
                              } else {
                                setEmailUserState("error");
                              }
                              
                            },
                            type: "text",
                            value: emailUser,
                            endAdornment:
                              emailUserState === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              )
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelLeftHorizontal}>
                          <code>email</code>
                        </FormLabel>
                      </GridItem>
                    </GridContainer>


                    {passwordJsxContainer()}


                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Nom 
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={7}>
                        <CustomInput
                          success={nomUserState === "success"}
                          error={nomUserState === "error"}
                          id="nomUser"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              if (verifyLength(event.target.value, 0)) {
                                setNomUserState("success");
                              } else {
                                setNomUserState("error");
                              }
                              
                              setNomUser(event.target.value);
                            },
                            type: "text",
                            value: nomUser,
                            endAdornment:
                              nomUserState === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              )
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelLeftHorizontal}>
                          <code>nom</code>
                        </FormLabel>
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Prenom
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={7}>
                        <CustomInput
                          success={prenomUserState === "success"}
                          error={prenomUserState === "error"}
                          id="prenomUser"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              if (verifyLength(event.target.value, 0)) {
                                setPrenomUserState("success");
                              } else {
                                setPrenomUserState("error");
                              }
                              
                              setPrenomUser(event.target.value);
                            },
                            type: "text",
                            value: prenomUser,
                            endAdornment:
                              prenomUserState === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              )
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelLeftHorizontal}>
                          <code>Prenom</code>
                        </FormLabel>
                      </GridItem>
                    </GridContainer>

                    

                    


                    
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          role
                        </FormLabel>
                  </GridItem>

                  <GridItem xs={12} sm={7} >
                   

                      <FormControl
                        fullWidth
                        className={classesExtended.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="role-select"
                          className={classesExtended.selectLabel}
                        >
                          
                        </InputLabel>
                        { roleSelectElem}
                      </FormControl>
                  </GridItem>

                </GridContainer>



                  <GridContainer>
                  <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          activation
                        </FormLabel>
                      </GridItem>

                    <GridItem xs={12} sm={7} >
                   

                      <FormControl
                        fullWidth
                        className={classesExtended.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="Userple-select"
                          className={classesExtended.selectLabel}
                        >
                          
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classesExtended.selectMenu
                          }}
                          classes={{
                            select: classesExtended.select
                          }}
                          value={activeUserSelect}
                          onChange={handleActiveUser}
                          inputProps={{
                            name: "UserpleSelect",
                            id: "Userple-select"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classesExtended.selectMenuItem
                            }}
                          >
                            Activation
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classesExtended.selectMenuItem,
                              selected: classesExtended.selectMenuItemSelected
                            }}
                            value={false}
                          >
                            Desactiver
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classesExtended.selectMenuItem,
                              selected: classesExtended.selectMenuItemSelected
                            }}
                            value={true}
                          >
                            Active
                          </MenuItem>
                          
                          
                        </Select>
                      </FormControl>
                    </GridItem>

                  </GridContainer>
                    
                  </form>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button color="info" onClick={typeClick}>
                    Validate Inputs
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            
          </GridContainer>
        );
      
}


const mapStateToProps = (state) => {
  // console.log('state user', state.users)  
  return (
      {
        loadUserFromSaga : state.users
      }
    )
  }

const mapsDispatchToProps = dispatch => {
    
    return (
      {
        userAddDispatch: (data) => {dispatch(addUserAction(data))},
        userUpdateDispatch: (data) => {dispatch(upadateUserAction(data))}
    })
  }


export default connect(mapStateToProps, mapsDispatchToProps)(UserUpdate);