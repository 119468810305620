import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";


// material-ui icons
import Assignment from "@material-ui/icons/Assignment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import {  useHistory } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import * as service from "../../../services/notificationService"
import moment from "moment";
import { useNavigate } from "react-router-dom-v5-compat";

const useStyles = makeStyles(styles);



const NotificationUser = (props) => {

  const [tabDataNotification, setTabDataNotification] = useState([])
  const [tabNotifContent, setTabNotifContent] = useState([])



  const getImageFromServer = (date, type, filename, isAVehiculeNotif = true) => 
  {
    const motoImg = require("../../../assets/img/vehiculeImage/moto.png")
    const autoImg = require("../../../assets/img/vehiculeImage/auto.png")
    const infoImg = require("../../../assets/img/autre/info.png")

    let status;
    let imageName;
    let autoMoto = ""
  
    //console.log("*********** filename ", " type", filename, " ", type);
    if (isAVehiculeNotif === true)
    {
      if ((filename === null) || (filename === undefined))
      {
        //console.log("*********** MIAOUUUUUUU ", " type", filename, " ", type);
        return (
          
              <img
              src={type === "Voiture" ? autoImg : motoImg}
              width={150}
              height={100}
              style={styles.imageVehicule}
/>
          )
      }
      return (
     
          <img
                                src={'https://www.sentracking.com/photmat/' + filename + ".png"}
                                width={150}
                                height={100}
                                style={styles.imageVehicule}
                />
      )
    }
    else
    {
      return (
        <img
        src={infoImg}
        width={150}
        height={100}
        style={styles.imageVehicule} />
        )
    }
    
  }

  useEffect(() => {

/*
    service.getListNotificationForOneUser().then((data) => {
      console.log("data = ", data)
    }).catch((error) => {
      console.log("error = ", error)
    })*/

    getNotification()
  }, [])


  const getNotification = async () => 
  {
    try {
      const data = await service.getListNotificationForOneUser()
    if (data.length > 0) 
    {
      setTabDataNotification(data)
    }
    } catch (error) {
      setTabDataNotification([])
    }
    
    
  }
  /*
    "identifiantNotification": 72701,
    "dateNotification": "2021-11-11T22:45:31.000+00:00",
    "numeroDevice": "78010836800",
    "body": "Attention SUSPITION DE VOL VEHICULE Toyota Auris DK2779BG",
    "title": "Alerte vol",
    "typeVehicule": "Voiture",
    "photoVehicule": "051a29ff407887446b86f3dbd6a58debc3d77c23",
    "avehiculeNotif": true
*/
  useEffect(() => {
   const tabTable = tabDataNotification.map((notif, cnt) => {
    return (
      [cnt + 1,  getImageFromServer("" , notif.typeVehicule, notif.photoVehicule, notif.avehiculeNotif), moment(notif.dateNotification).format("DD-MM-YYYY HH:mm:ss"), notif.title,  notif.body]
    )
   }
   )
   setTabNotifContent(tabTable);
  }, [tabDataNotification])

    const history = useNavigate();

    const handleRefresh = () => {
      getNotification();
    }

    const classes = useStyles();

    return (
        <GridContainer>
          
          <GridItem xs={12}>
          <div className="d-flex justify-content-end">
              <Button className="mr-2" color="info" onClick={()=>{handleRefresh()}} disabled={false}>
                 Refresh List
              </Button>
              
              
            </div>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>device</h4>
              </CardHeader>
              <CardBody>
                <Table
                  tableHead={[
                    "#",
                    "notif",
                    "Date",
                    "libele",
                    "notification",
                    
                  ]}
                  tableData={tabNotifContent}
                  customCellClasses={[classes.center, classes.right, classes.right]}
                  customClassesForCells={[0, 4, 5]}
                  customHeadCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right
                  ]}
                  customHeadClassesForCells={[0, 4, 5]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
    
      )
}


const style = {
  imageVehicule: {
      position: "relative",
      width: "100%",
      height: "auto",
      verticalAlign: "top",
      background: "hsl(0, 0%, 98%)"
    },
    cardBody: {
      height: 230
      //color: "blue"
    }
}

export default NotificationUser;