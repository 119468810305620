import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
//import Setting from "@material-ui/icons/Settings";
import User from "@material-ui/icons/SupervisedUserCircle";
import Bike from "@material-ui/icons/SportsMotorsportsRounded";
import Money from "@material-ui/icons/EuroSymbol";
import SettingIcon from "@material-ui/icons/Settings"
//import FormatQuote from "@material-ui/icons/FormatQuote";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
//import Timeline from "components/Timeline/Timeline.js";
import Card from "components/Card/Card.js";
//import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
//import {  useHistory, useRouteMatch } from "react-router";
//import CardFooter from "components/Card/CardFooter.js";

//import { widgetStories } from "variables/general.js";

//import image from "assets/img/faces/card-profile1-square.jpg";

import {
  cardTitle,
  roseColor
} from "assets/jss/material-dashboard-pro-react.js";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";
import { useRouteMatch } from "react-router-dom";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px"
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px"
    }
  },
  iconRose: {
    color: roseColor
  },
  marginTop30: {
    marginTop: "30px"
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px"
    }
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999"
  }
};



const useStyles = makeStyles(styles);

const ClientDetail = (props) => {

  const actualURL = useRouteMatch();
  const history = useNavigate();
const [paramId, setParamId] = useState("")     

const  paramURLID   = useParams().id;
     
useEffect(() => {
  setParamId(paramURLID)
}, [])


  const classes = useStyles();

const handleUser = () => {
  history(`${actualURL.url}/user`)
}


const handleVehicule = () => {
  history(`${actualURL.url}/vehicule`)
}

const handlePaiement = () => {
  history(`${actualURL.url}/paiement`)
}


const handleParametrageUser = () => {
  history(`${actualURL.url}/parametreuser`)
}



  return (
    <div>
      
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={6} sm={6} lg={6}>
              <Card pricing>
                <CardBody pricing>
                <h6 className={classes.cardCategory}></h6>
                  
                  <div className={classes.icon}>
                    <User className={classes.iconRose} />
                  </div>
                  <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                    
                  </h3>
                  <p className={classes.cardDescription}>
                    Manager vos utilisateurs.
                  </p>
                  <Button round color="info"
                   onClick={handleUser} 
                  >
                    Cliquer
                  </Button>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} lg={6}>
              <Card pricing>
                <CardBody pricing>
                  <h6 className={classes.cardCategory}></h6>
                  <div className={classes.icon}>
                    <Bike className={classes.iconRose} />
                  </div>
                  <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                    
                  </h3>
                  <p className={classes.cardDescription}>
                    Gerer votre parc de vehicule
                  </p>
                  <Button round color="info"
                  onClick={handleVehicule}
                  >
                  Cliquer
                  </Button>
                </CardBody>
              </Card>
            </GridItem>


            <GridItem xs={12} sm={12} lg={6}>
              <Card pricing>
                <CardBody pricing>
                  <h6 className={classes.cardCategory}></h6>
                  <div className={classes.icon}>
                    <Money className={classes.iconRose} />
                  </div>
                  <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                    
                  </h3>
                  <p className={classes.cardDescription}>
                    Gerer vos paiements
                  </p>
                  <Button round color="info"
                  onClick={handlePaiement}
                  >
                  Cliquer
                  </Button>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} lg={6}>
              <Card pricing>
                <CardBody pricing>
                  <h6 className={classes.cardCategory}></h6>
                  <div className={classes.icon}>
                    <SettingIcon className={classes.iconRose} />
                  </div>
                  <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                    
                  </h3>
                  <p className={classes.cardDescription}>
                    Parametrage et configuration
                  </p>
                  <Button round color="info"
                  onClick={handleParametrageUser}
                  >
                  Cliquer
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
            
          </GridContainer>
        </GridItem>

        
        
      </GridContainer>
    </div>
  );
}

export  default ClientDetail;
