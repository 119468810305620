import * as types from '../config/actionType'



export const loadPaiementRequestAction = () => {
    return (
        {
            type: types.LOAD_PAIEMENT_REQ
        }
    )
}

export const loadPaiementErrorAction = (error) => {
    return (
        {
            type: types.LOAD_PAIEMENT_ERROR,
            error
        }
    )
}

export const loadPaiementFinishAction = () => {
    return (
        {
            type: types.LOAD_PAIEMENT_FINISH
        }
    )
}



export const loadPaiementForCustomerAction = (client) => {
    return (
        {
            type: types.LOAD_PAIEMENTS_CLIENT,
            client
        }
    )
}


export const loadPaiementAction = (client) => {
    return (
        {
            type: types.LOAD_PAIEMENTS,
            client
        }
    )
}


export const paiementLoadedAction = (paiements) => {
    return (
        {
            type: types.PAIEMENTS_LOADED,
            paiements: paiements
        }
    )
}

export const deletePaiementAction = (paiement) => {
    
    return (
        {
            type: types.DELETE_PAIEMENTS,
            paiement
        }
    )
}


export const addPaiementAction = (paiement) => {
    
    return (
        {
            type: types.ADD_PAIEMENTS,
            paiement
        }
    )
}



export const upadatePaiementAction = (paiement) => {
    
    return (
        {
            type: types.UPDATE_PAIEMENTS,
            paiement
        }
    )
}