import { LOAD_PARAM_USER_REQ, LOAD_PARAM_USER_REQ_ERROR, LOAD_PARAM_USER_REQ_FINISH, PARAM_USER_LOADED } from "../config/actionType";


const initialState = {
    errorMessage: "",
    isError : false,
    param: [],
    loading: false
}


export const paramUserReducer = (state = initialState, action) => {
    switch (action.type)
    {
        case LOAD_PARAM_USER_REQ:
            return (
                {
                    ...state,
                    loading: true,
                    isError: false,
                    errorMessage: ""
                }
            )
            case LOAD_PARAM_USER_REQ_FINISH:
            return (
                {
                    ...state,
                    loading: false,

                }
            )
            /*case LOAD_PARAM_USER:
                
                return (
                    {
                        ...state,
                        param: action.param,
                    }
                )
            */
           case PARAM_USER_LOADED:
               //console.log("ACTION = ", action)
               return (
                   {
                       ...state,
                       param: action.param.parametres,
                       isError: false,
                       errorMessage: ""
                   }
               )
            case LOAD_PARAM_USER_REQ_ERROR:
                return (
                    {
                       ...state,
                       errorMessage: "Erreur requete",
                       loading: false,
                       isError : true
                    }
                )

           /*
                case UPDATE_PARAM_USER:
                return (
                    {
                        ...state,
                    }
                )
                */

        default:
            return state
    }
}
