/*eslint-disable*/
import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";


import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { addClientAction, upadateClientAction } from "action/clientAction";

// style for this view
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { useHistory } from "react-router";
import { connect } from "react-redux";

import * as service from '../../services/clientService'
import * as notif from "components/Notification/notification"
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

const useStyles = makeStyles(styles);
const useStylesExtended = makeStyles(stylesExtended);


const ClientUpdate = (props) => 
{

  const history = useNavigate();
  const [paramId, setParamId] = useState("")     
  
  const [openNotif, setOpenNotif] = useState(false)

  // type validation
  const [adresseClient, setAdresseClient] = React.useState("");
  const [adresseClientState, setAdresseClientState] = React.useState("");

  const [ninea, setNinea] = React.useState("");
  const [nineaState, setNineaState] = React.useState("");

  const [emailClient, setEmailClient] = React.useState("");
  const [emailClientState, setEmailClientState] = React.useState("");

  const [nomClient, setNomClient] = React.useState("");
  const [nomClientState, setNomClientState] = React.useState("");

  const [tel1Client, setTel1Client] = React.useState("");
  const [tel1ClientState, setTel1ClientState] = React.useState("");


  const [tel2Client, setTel2Client] = React.useState("");
  const [tel2ClientState, setTel2ClientState] = React.useState("");


  const [typeClient, setTypeClient] = React.useState("");
  const [typeClientState, setTypeClientState] = React.useState("");


  const [activeClientSelect, setActiveClientSelect] = React.useState(false);


  const  paramURLID   = useParams().id;
     
  useEffect(() => {
    setParamId(paramURLID)
  }, [])


    const handleActiveClient = event => 
    {
      setActiveClientSelect(event.target.value);
    };

    useEffect(()=> {

      if (openNotif === true)
      {
        if (props.loadClientFromSaga.loading === false)
        {
          let msg;
            let title;
            
          if (props.loadClientFromSaga.error.length > 0)
          {
            if (paramId)
            {
              title = "Echeque Mise a jour Client"
              msg = "Mise a jours Client echoué";
            }
            else
            {
              title = "Echeque ajout Client"
              msg = "La Client " + nomClient + " n a pas ete ajouter";
            }
            notif.showFailedNotification(title, msg)
            
          }
          else
          {
            if (paramId)
            {
              title = "Mise a jour Client"
              msg = "La Client " + nomClient + "  a ete mise a jours";
            }
            else
            {
              title = "Ajout  Client"
              msg = "La Client " + nomClient + " a ete ajouter";
            }
            notif.showSuccessNotification(title, msg)
          }
          setOpenNotif(false)
      }
    }
      
    }, [props.loadClientFromSaga])

    useEffect(() => 
    {
      if (paramId)
      {
        
        const data = {
          'numeroclient' : paramId
        }

        
        

        service.getItemClient(data).then(
          (res) => {
          //  console.log("getItemClient = ",res)
            

            setAdresseClient(res.adresseClient)
            if (verifyLength(res.adresseClient, 0))
            {
              setAdresseClientState("success");
            }
            if (res.ninea)
            {
              setNinea(res.ninea)
              if (verifyLength(res.ninea, 0))
              {
                setNineaState("success");
              }
          }
            
            setEmailClient(res.emailClient)
            if (verifyLength(res.emailClient, 0))
            {
              setEmailClientState("success");
            }
            setNomClient(res.nomClient)
            if (verifyLength(res.nomClient, 0))
            {
              setNomClientState("success");
            }

            setTypeClient(res.type)
            if (verifyLength(res.type, 0))
            {
              setTypeClientState("success");
            }
            
            setTel1Client(res.telephone1)
            if (verifyLength(res.telephone1, 0))
            {
              setTel1ClientState("success");
            }

            setTel2Client(res.telephone2)
            if (verifyLength(res.telephone2, 0))
            {
              setTel2ClientState("success");
            }
          //  console.log(res)
           /* 
            
            
            
            
            //setactivation(res.type)
            setactivationState("success");
*/
          }).catch(
            (error) => {
              //console.log('error = ',error)
            }
          )
      }
      
    }, [paramId])
       
   

       
        const successDelete = () => {
            setAlert(
              <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Deleted!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
              >
                Your imaginary file has been deleted.
              </SweetAlert>
            );
          };
          const cancelDetele = () => {
            setAlert(
              <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Cancelled"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
              >
                Your imaginary file is safe :)
              </SweetAlert>
            );
          };
          const hideAlert = () => {
            setAlert(null);
          };
        
        // function that returns true if value is email, false otherwise
        const verifyEmail = value => {
          var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (emailRex.test(value)) {
            return true;
          }
          return false;
        };
      
        
        // function that verifies if a string has a given length or not
        const verifyLength = (value, length) => {
          if (value.length > length) {
            return true;
          }
          return false;
        };
        // function that verifies if value contains only numbers
        const verifyNumber = value => {
          var numberRex = new RegExp("^[0-9]+$");
          if (numberRex.test(value)) {
            return true;
          }
          return false;
        };
       
        
        const typeClick = () => 
        {
            let error = false;

          if (adresseClient === "") {
            setAdresseClientState("error");
            error = true;
          }

          if (ninea === "") 
          {
            error = true;
            setNineaState("error");
          }

          if (emailClient === "") 
          {
            error = true;
            setEmailClientState("error");
          }

          if (nomClient === "") 
          {
            error = true;
            setNomClientState("error");
          }

          if (tel1Client === "") 
          {
            error = true;
            setTel1ClientState("error");
          }

          if (tel2Client === "") 
          {
            error = true;
            setTel2ClientState("error");
          }

          if (typeClient === "") 
          {
            error = true;
            setTypeClientState("error");
          }

          if (activeClientSelect === "") 
          {
            error = true;
            
          }
/*
{
  "identifiantClient": 1,
  "adresseClient": "Adresse",
  "emailClient": "email@ccc.com",
  "etatClient": "1",
  "ninea": "2222",
  "nomClient": "taphus",
  "telephone1": "555555",
  "telephone2": "555555",
  "type": "333333"
}
*/
          const data = {
            
            "adresseClient": adresseClient,
            "emailClient": emailClient,
            "etatClient": activeClientSelect === true ? 1 : 0,
            "nomClient": nomClient,
            "ninea": ninea,
            "telephone1": tel1Client,
            "telephone2": tel2Client,
            "type": typeClient,
            "identifiantClient": paramId
            
          }

          if (error === false)
          {
            
            setOpenNotif(true)
            if (paramId)
            {
              props.ClientUpdateDispatch(data);
              
            }
            else
            {
              props.ClientAddDispatch(data);
              
              
            }
            
            
            //history('/')
            
          }
          
        };


        const classes = useStyles();
        const classesExtended = useStylesExtended();


        /*
        
etatClient: "1"
telephone1: "555555"
telephone2: "555555"
type: "333333"
        */
        
        return (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" text>
                  <CardText color="info">
                    <h4 className={classes.cardTitle}>Type Validation</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Adresse
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={7}>
                        <CustomInput
                          success={adresseClientState === "success"}
                          error={adresseClientState === "error"}
                          id="adresseClient"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {

                              
                              if (verifyLength(event.target.value, 0)) 
                              {
                                setAdresseClientState("success");
                              } else {
                                setAdresseClientState("error");
                              }
                              setAdresseClient(event.target.value);
                            },
                            value: adresseClient,

                            type: "text",
                            endAdornment:
                              adresseClientState === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              )
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelLeftHorizontal}>
                          <code>adresse</code>
                        </FormLabel>
                      </GridItem>
                    </GridContainer>
                    

                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Ninea
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={7}>
                        <CustomInput
                          success={nineaState === "success"}
                          error={nineaState === "error"}
                          id="ninea"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              if (verifyLength(event.target.value, 0)) {
                                setNineaState("success");
                              } else {
                                setNineaState("error");
                              }
                              setNinea(event.target.value);
                            },
                            value: ninea,
                            type: "text",
                            endAdornment:
                              nineaState === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              )
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelLeftHorizontal}>
                          <code>ninea</code>
                        </FormLabel>
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Email
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={7}>
                        <CustomInput
                          success={emailClientState === "success"}
                          error={emailClientState === "error"}
                          id="emailClient"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              if (verifyLength(event.target.value, 0)) {
                                setEmailClientState("success");
                              } else {
                                setEmailClientState("error");
                              }
                              setEmailClient(event.target.value);
                            },
                            type: "text",
                            value: emailClient,
                            endAdornment:
                              emailClientState === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              )
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelLeftHorizontal}>
                          <code>email</code>
                        </FormLabel>
                      </GridItem>
                    </GridContainer>


                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Nom Prenom
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={7}>
                        <CustomInput
                          success={nomClientState === "success"}
                          error={nomClientState === "error"}
                          id="nomClient"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              if (verifyLength(event.target.value, 0)) {
                                setNomClientState("success");
                              } else {
                                setNomClientState("error");
                              }
                              if (!paramId)
                              setNomClient(event.target.value);
                            },
                            type: "text",
                            value: nomClient,
                            endAdornment:
                              nomClientState === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              )
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelLeftHorizontal}>
                          <code>nom</code>
                        </FormLabel>
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Telephone 1
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={7}>
                        <CustomInput
                          success={tel1ClientState === "success"}
                          error={tel1ClientState === "error"}
                          id="tel1Client"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              if (verifyLength(event.target.value, 0)) {
                                setTel1ClientState("success");
                              } else {
                                setTel1ClientState("error");
                              }
                              if (!paramId)
                              setTel1Client(event.target.value);
                            },
                            type: "text",
                            value: tel1Client,
                            endAdornment:
                              tel1ClientState === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              )
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelLeftHorizontal}>
                          <code>nom</code>
                        </FormLabel>
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Telephone 2
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={7}>
                        <CustomInput
                          success={tel2ClientState === "success"}
                          error={tel2ClientState === "error"}
                          id="tel2Client"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              if (verifyLength(event.target.value, 0)) {
                                setTel2ClientState("success");
                              } else {
                                setTel2ClientState("error");
                              }
                              if (!paramId)
                              setTel2Client(event.target.value);
                            },
                            type: "text",
                            value: tel2Client,
                            endAdornment:
                              tel2ClientState === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              )
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelLeftHorizontal}>
                          <code>tel2</code>
                        </FormLabel>
                      </GridItem>
                    </GridContainer>


                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Type
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={7}>
                        <CustomInput
                          success={typeClientState === "success"}
                          error={typeClientState === "error"}
                          id="typeClient"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              if (verifyLength(event.target.value, 0)) {
                                setTypeClientState("success");
                              } else {
                                setTypeClientState("error");
                              }
                              if (!paramId)
                              setTypeClient(event.target.value);
                            },
                            type: "text",
                            value: typeClient,
                            endAdornment:
                              typeClientState === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              )
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelLeftHorizontal}>
                          <code>Type</code>
                        </FormLabel>
                      </GridItem>
                    </GridContainer>
                    



                  <GridContainer>
                  <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          activation
                        </FormLabel>
                      </GridItem>

                    <GridItem xs={12} sm={7} >
                   

                      <FormControl
                        fullWidth
                        className={classesExtended.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="Clientple-select"
                          className={classesExtended.selectLabel}
                        >
                          
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classesExtended.selectMenu
                          }}
                          classes={{
                            select: classesExtended.select
                          }}
                          value={activeClientSelect}
                          onChange={handleActiveClient}
                          inputProps={{
                            name: "ClientpleSelect",
                            id: "Clientple-select"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classesExtended.selectMenuItem
                            }}
                          >
                            Activation
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classesExtended.selectMenuItem,
                              selected: classesExtended.selectMenuItemSelected
                            }}
                            value={false}
                          >
                            Desactiver
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classesExtended.selectMenuItem,
                              selected: classesExtended.selectMenuItemSelected
                            }}
                            value={true}
                          >
                            Active
                          </MenuItem>
                          
                          
                        </Select>
                      </FormControl>
                    </GridItem>

                  </GridContainer>
                    
                  </form>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button color="info" onClick={typeClick}>
                    Validate Inputs
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            
          </GridContainer>
        );
      
}


const mapStateToProps = (state) => {
  //console.log('state Client', state.clients)  
  return (
      {
        loadClientFromSaga : state.clients
      }
    )
  }

const mapsDispatchToProps = dispatch => {
    
    return (
      {
        ClientAddDispatch: (data) => {dispatch(addClientAction(data))},
        ClientUpdateDispatch: (data) => {dispatch(upadateClientAction(data))}
    })
  }


export default connect(mapStateToProps, mapsDispatchToProps)(ClientUpdate);