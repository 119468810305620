import {takeEvery, call, put, all} from 'redux-saga/effects'

import * as types from '../config/actionType'
import * as service from '../services/userService'
import * as actions from '../action/userAction'

function* loadUsersByCustomer(data)
{
    try {
        yield put(actions.loadUserRequestAction())
        const users = yield call(service.getListUserByCustomer, data)
        yield put(actions.userLoadedAction(users))

    } catch (error) 
    {
        yield put(actions.loadUserErrorAction(error))
        yield put(actions.userLoadedAction([]))
        
    }
    finally
    {
        yield put(actions.loadUserFinishAction())
    }
}



function* addUser(data)
{
    try {
        
        yield put(actions.loadUserRequestAction())
        yield call(service.addUserService, data)
        //yield put(actions.loadUserAction())

    } catch (error) 
    {
        yield put(actions.loadUserErrorAction(error))
        
    }
    finally
    {
        yield put(actions.loadUserFinishAction())
    }
}

function* updateUser(data)
{
    try {
        
        yield put(actions.loadUserRequestAction())
        yield call(service.updateUserService, data)
        //yield put(actions.loadUserAction())

    } catch (error) 
    {
        yield put(actions.loadUserErrorAction(error))
        
    }
    finally
    {
        yield put(actions.loadUserFinishAction())
    }
}


function* deleteUser(data)
{
    try {

        yield put(actions.loadUserRequestAction())
        yield call(service.deleteUserService, data)
        yield put(actions.loadUserAction())

    } catch (error) 
    {
        yield put(actions.loadUserErrorAction(error))
        
    }
    finally
    {
        yield put(actions.loadUserFinishAction())
    }
}


function* watchLoadUsers()
{
 yield takeEvery(types.LOAD_USERS, loadUsersByCustomer)
}

function* watchAddUsers()
{
 yield takeEvery(types.ADD_USERS, addUser)
}

function* watchUpdateUsers()
{
 yield takeEvery(types.UPDATE_USERS, updateUser)
}

function* watchDeleteUsers()
{
 yield takeEvery(types.DELETE_USERS, deleteUser)
}

export function* userSaga() 
{
    yield all ([
        watchLoadUsers(),
        watchAddUsers(),
        watchUpdateUsers(),
        watchDeleteUsers(),
    ])
}