//import { store } from 'react-notifications-component';
import { ReactNotifications, Store } from 'react-notifications-component'

export const showSuccessNotification = ( title, mes) => {
  Store.addNotification({
        title: title,
        message: mes,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 4000,
          onScreen: true
        }
      });
}

export const showFailedNotification = ( title, mes) => {
  Store.addNotification({
        title: title,
        message: mes,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 4000,
          onScreen: true
        }
      });
}

export const showInfoNotification = ( title, mes) => {
  Store.addNotification({
        title: title,
        message: mes,
        type: "info",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 4000,
          onScreen: true
        }
      });
}

export const showWarningNotification = ( title, mes) => {
  Store.addNotification({
        title: title,
        message: mes,
        type: "warning",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 4000,
          onScreen: true
        }
      });
}