
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
//import Checkbox from "@material-ui/core/Checkbox";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";

import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";


import * as notif from "components/Notification/notification"

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";



import {  useHistory, useParams, useRouteMatch } from "react-router-dom";
import { loadUserAction, deleteUserAction} from "action/userAction";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { Details, Person, Search } from "@material-ui/icons";
import { useNavigate } from "react-router-dom-v5-compat";


//import product1 from "assets/img/product1.jpg";

const useStyles = makeStyles(styles);
const useStyleAlert = makeStyles(stylesAlert)


const User = (props) => 
{
  const actualURL = useRouteMatch();
  const [tableData, setTableData] = useState([]);
  const history = useNavigate();
  const {loadUserFromSaga, userLoadDispatch, userDeleteDispatch} = props;
  const [alert, setAlert] = React.useState(null);
  const [deleteStatut, setDeleteStatut] = React.useState(false);
  const [paramId, setParamId] = useState("")
  
  const  paramURLID   = useParams().id;
     
  useEffect(() => {
    setParamId(paramURLID)
    console.log("[User] paramURLID => ", paramURLID)
  }, [])


  
  const dispatchRefreshTable = useCallback(() => 
  {
      if ((paramId !== undefined) &&  (paramId > 0))
      {
      const data = {
        "identifiantClient": paramId
      }

      userLoadDispatch(data)
  }

  }, [userLoadDispatch])


  const loadCardFromSagaUnique = useCallback(() => {
    return (loadUserFromSaga)
  }, [loadUserFromSaga])


  useEffect(() => {
    dispatchRefreshTable()
  },[dispatchRefreshTable])


useEffect(() => {
  handleRefresh();
}, [paramId])

  useEffect(() => 
  {
    if (deleteStatut === true)
    {
      if (loadUserFromSaga.loading === false)
        {
          let msg;
            let title;
          if (loadUserFromSaga.error.length > 0)
          {
  
            title = "Echeque Supression user"
              msg = "La user n a pas ete suprime";
            notif.showFailedNotification(title, msg)
            
          }
          else
          {
            title = "Supression user"
              msg = "La user   a ete suprimer";
            notif.showSuccessNotification(title, msg)
            setAlert(
              <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Suprimer!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
              >
                Cette article a ete supprimer.
              </SweetAlert>
            );
          }
          setDeleteStatut(false);
        }
    }

  }, [deleteStatut, loadUserFromSaga])
  

  const handleRefresh = () => {
    if (paramId)
    {
      const data = {
        "identifiantClient": paramId
      }
    // console.log("*** handleRefresh *** = ",data)
      userLoadDispatch(data)
    }
  }


  const successDelete = (id) => {
    
    const data = 
    {
      'numeroDeSerieUser' : id
    }

    userDeleteDispatch(data);
    setDeleteStatut(true)
    /*
    
    */
  };
  const cancelDetele = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Annuler"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
      >
        Votre article n a pas ete supprimer
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };




/*
active: true
dateReceptionuser: "2020-11-11T00:00:00.000+00:00"
etatUser: "new"
identifiantUser: 8
numerouser: "778188858"
numeroDeCommandeUser: "command"
numeroDeSerieUser: "444433"
*/

const warningWithConfirmAndCancelMessage = (id) => {
  setAlert(
    <SweetAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="Etes vous sure ?"
      onConfirm={() => successDelete(id)}
      onCancel={() => cancelDetele()}
      confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
      cancelBtnCssClass={classesAlert.button + " " + classesAlert.danger}
      confirmBtnText="Suprimer!"
      cancelBtnText="Annuler"
      showCancel
    >
      Vous ne pourez pas recuperer cette article!
    </SweetAlert>
  );
};


const classes = useStyles();
const classesAlert = useStyleAlert();

const handleButDelete = (id) => 
{
  warningWithConfirmAndCancelMessage(id);
 // console.log("CLIKKKKKK DELETE " + id)
}

const handleButUpdate = (id) => {
 // console.log("CLIKKKKKK UPDATE " + id)

  history(`${actualURL.url}/${id}/edit`)

}

const handleButShowDetail = (id) => {
  //console.log("CLIKKKKKK DETAIL "  + id)
  history(`${actualURL.url}/${id}/detail`)
}
 

const roundButtons = (id) => 
{
  const tabRound = [
    { color: "info", icon: Details , onClick: handleButShowDetail},
    { color: "success", icon: Edit , onClick: handleButUpdate},
  { color: "danger", icon: Close, onClick: handleButDelete }
].map((prop, key) => {
  return (
    <Button
      round
      color={prop.color}
      className={classes.actionButton + " " + classes.actionButtonRound}
      key={key}
      onClick={() => {prop.onClick(id)}}

    >
      <prop.icon className={classes.icon} />
    </Button>
  );
});
return tabRound
}

useEffect(() => 
{
  

 const tabLoad = loadUserFromSaga.users;

// console.log('***** Tab Load *********', tabLoad)
 let table = [];
 let cnt = 0;
 if (tabLoad !== undefined)
 {
   table = tabLoad.map((row) => 
  {
    cnt++;
   
    let role = ''
    if (row.roles.length > 0)
    {
      role = row.roles[0].role
    }
    
    return(
      [
        cnt,
        row.email,  row.nom + " " + row.prenom, row.clientNom, role, roundButtons(row.id)
      ]
    )
     
  }
  )
  setTableData(table)
 }

}, [loadCardFromSagaUnique])


 

  return (
    <GridContainer>
      {alert}
      <GridItem xs={12}>
      <div className="d-flex justify-content-end">
          <Button className="mr-2" color="info" onClick={()=>{handleRefresh()}} disabled={false}>
             Refresh List
          </Button>
          <Button className="mr-2" color="info" onClick={()=>{ history(`${actualURL.url}/new`) }} disabled={false}>
             Ajouter user
          </Button>
          
        </div>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>User</h4>
          </CardHeader>
          <CardBody>
            <Table
              tableHead={[
                "#",
                "email",
                "Nom",
                "Nom Client",
                "Role",
                "Action",
              ]}
              tableData={tableData}
              customCellClasses={[classes.center, classes.right, classes.right]}
              customClassesForCells={[0, 4, 5]}
              customHeadCellClasses={[
                classes.center,
                classes.right,
                classes.right
              ]}
              customHeadClassesForCells={[0, 4, 5]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>

  )
}


const mapStateToProps = (state) => {
  return (
    {
      loadUserFromSaga : state.users
    }
  )
}

const mapsDispatchToProps = dispatch => {
  return (
    {
      userLoadDispatch: (data) => {dispatch(loadUserAction(data))},
      userDeleteDispatch: (data) => {dispatch(deleteUserAction(data))},      
  })
}

export default connect(mapStateToProps, mapsDispatchToProps)(User);