
import axios from 'axios'
import {  listeNotificationURL } from '../config/api'
import { encryptStorage } from 'components/EncryptedStorage/encryptedStorage';



export const getListNotificationForOneUser = async () => 
{

   
    const userInfo =  await encryptStorage.getItem('userInfo');
   // console.log("userInfo  = ", userInfo)
   // const userInfoObj = JSON.parse(userInfo);
   
    const token = "Bearer " + userInfo.userToken;
    const user = userInfo.userName;
    const userNameToSend = {email: user}

   

       return axios.post(listeNotificationURL , userNameToSend, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
}



