import { USERS_LOADED, LOAD_USER_REQ, LOAD_USER_FINISH, LOAD_USER_ERROR } from "../config/actionType";


const initialState = {
    loading: false,
    users: [],
    error: ""
};

export const userReducer = (state = initialState, action) => {
    switch (action.type)
    {
        case USERS_LOADED:
            return (
                {
                    ...state,
                   users: action.users,
                }
            )
            case LOAD_USER_REQ:
            return (
                {
                   ...state,
                   loading: true,
                   error: ""
                }
            )
            case LOAD_USER_ERROR:
                return (
                    {
                       ...state,
                       error: "Erreur requete"
                    }
                )
                case LOAD_USER_FINISH:
                return (
                    {
                       ...state,
                       loading: false,
                    }
                )

        default:
            return state
    }
}
