import { PAIEMENTS_LOADED, LOAD_PAIEMENT_REQ, LOAD_PAIEMENT_FINISH, LOAD_PAIEMENT_ERROR } from "../config/actionType";


const initialState = {
    loading: false,
    paiements: [],
    error: ""
};

export const paiementReducer = (state = initialState, action) => {
    switch (action.type)
    {
        case PAIEMENTS_LOADED:
            return (
                {
                    ...state,
                   paiements: action.paiements,
                }
            )
            case LOAD_PAIEMENT_REQ:
            return (
                {
                   ...state,
                   loading: true,
                   error: ""
                }
            )
            case LOAD_PAIEMENT_ERROR:
                return (
                    {
                       ...state,
                       error: "Erreur requete"
                    }
                )
                case LOAD_PAIEMENT_FINISH:
                return (
                    {
                       ...state,
                       loading: false,
                    }
                )

        default:
            return state
    }
}
