import axios from 'axios'
import { listeRoleURL } from 'config/api'

import { encryptStorage } from 'components/EncryptedStorage/encryptedStorage';

    
export const getRoles = async () => 
{
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;

       return axios.get(listeRoleURL , {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
}
