import React, { useEffect, useState } from "react";
// react plugin for creating charts

// @material-ui/core components

import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Danger from "components/Typography/Danger.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import Language from "@material-ui/icons/Language";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
// react plugin for creating charts
import ChartistGraph from "react-chartist";


import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import stylesChartlist from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { getDashboardInformation } from "services/dashboardService";

const useStyles = makeStyles(styles);
const useStylesChartlist = makeStyles(stylesChartlist);

ChartJS.register(ArcElement, Tooltip, Legend);


export const dataTest = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 0, 0, 1)',
        'rgba(54, 162, 235, 0.2)',
        
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};


export const initdata = {
  labels: [],
  datasets: [
    {
      label: '# of Votes',
      data: [],
      backgroundColor: [
        
      ],
      borderColor: [
        
      ],
      borderWidth: 1,
    },
  ],
};

const initStatistic = {
  nomrbreVehiculesArrets: 0,
  nombreVehiculesEnMarche: 0,
  nombreVehiculesNonsConnectes: 0,
  nombreVehiculesConnectes: 0,
  nombreTotalVehicules: 0,
  typeVehicules : []
}
const initChartTab = {
  data: {
    labels: ["100"],
    series: [100]
  },
  options: {
    height: "230px"
  }
};

const DashboardUser = () => 
{
  const [statistic, setStatistic] = useState(initStatistic);
  const [tabDiagramEtatVehicule, setTabDiagramEtatVehicule] = useState(initdata);
  const [tabDiagramTypeVehicule, setTabDiagramTypeVehicule] = useState(initdata);
  const classes = useStyles();
  const classesChartList = useStylesChartlist();
  
  const pieChart = {
    data: {
      labels: ["62%", "32%", "6%"],
      series: [62, 32, 6]
    },
    options: {
      height: "230px",
      reverseData: false
    }
  };



  const getInfoDashAPI = async () => 
  {
    try {
      const dash = await getDashboardInformation()
      return (dash); 
      
    }
    catch (error)
    {
      console.error.apply("error getInfoDashAPI = ", error)
      return (null)
    }
    
  } 

  useEffect(() => {

    const run = async () => {
      const infoDash = await getInfoDashAPI();
      if (infoDash !== null)
      setStatistic(infoDash)
    }
    run();
  }, [])

  

  useEffect(() => 
  {
    
    const tabDataValue = [Math.round((statistic.nombreVehiculesEnMarche * 100) / statistic.nombreTotalVehicules, (statistic.nomrbreVehiculesArrets * 100) / statistic.nombreTotalVehicules, (statistic.nombreVehiculesNonsConnectes * 100) / statistic.nombreTotalVehicules)];
    const tabDataLabel = tabDataValue.map((value) => {
      return (value + "%")
    })
    const tabTypeVehicule = statistic.typeVehicules.map((type) => {
      return (Math.round((type.nbVehicules * 100) / statistic.nombreTotalVehicules))
    })
    const tabTypeVehiculeLabel = statistic.typeVehicules.map((type, cnt) => {
      return (type.typeVehicule + " " + Math.round((type.nbVehicules * 100) / statistic.nombreTotalVehicules)) + "%"
    })

    /*const initTab = {
      data: {
        labels: tabDataLabel,
        series: tabDataValue
      },
      options: {
        height: "230px"
      }
    }*/

    const tabStat = {
      labels: tabDataLabel,
      datasets: [
        {
          label: '# of Votes',
          data: tabDataValue,
          backgroundColor: [
            'rgba(0, 255, 0, 1)',
             'rgba(255,129,0,1)',
             'rgba(255,0,0,1)'

          ],
          borderColor: [
            'rgba(255, 0, 0, 1)',
           'rgba(255, 0, 0, 1)',
           'rgba(255, 0, 0, 1)'
          ],
          borderWidth: 1,
        },
      ]
    }
    

    setTabDiagramEtatVehicule(tabStat);

    const tabAngin = {
      labels: tabTypeVehiculeLabel,
      datasets: [
        {
          label: '# of Votes',
          data: tabTypeVehicule,
          backgroundColor: [
            "#2f5ae7",
            "#faf725",
            "#b059ed"

          ],
          borderColor: [
            'rgba(255, 0, 0, 1)',
           'rgba(255, 0, 0, 1)',
           'rgba(255, 0, 0, 1)'
          ],
          borderWidth: 1,
        },
      ]
    }

    setTabDiagramTypeVehicule(tabAngin);
    
  }, [statistic])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Vehicule en marche</p>
              <h3 className={classes.cardTitle}>
                {statistic.nombreVehiculesEnMarche}/{statistic.nombreTotalVehicules} 
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Derniere 24 Heures
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Vehicules Eteint</p>
              <h3 className={classes.cardTitle}>{statistic.nomrbreVehiculesArrets}/{statistic.nombreTotalVehicules} </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Derniere 24 Heures
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Traceur non connecté</p>
              <h3 className={classes.cardTitle}>{statistic.nombreVehiculesNonsConnectes}/{statistic.nombreTotalVehicules} </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Derniere 24 Heures
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <i className="fab fa-twitter" />
              </CardIcon>
              <p className={classes.cardCategory}>Traceur connecté </p>
              <h3 className={classes.cardTitle}>{statistic.nombreVehiculesConnectes}/{statistic.nombreTotalVehicules} </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Derniere 24 Heures
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
      <GridItem xs={12}>
      .
      </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Language />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Park de vehicules
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer justifyContent="space-between">
                <GridItem xs={12} sm={12} md={5}>
                <Pie  
                       data={tabDiagramTypeVehicule} 
                       width={10}
                       height={50} 
                       
                       />
                    
                    <CardFooter stats className={classesChartList.cardFooter}>
                      <h6 className={classesChartList.legendTitle}>Legend</h6>
                      <i className={"fas fa-circle " + classesChartList.blue} /> Moto{` `}
                      <i className={"fas fa-circle " + classesChartList.yellow} /> Voiture
                      {` `}
                      <i className={"fas fa-circle " + classesChartList.indigo} /> Camion
                      {` `}
                    </CardFooter>
                  
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  
                  
            
                    {
                      /*
                         <ChartistGraph
                        data={tabDiagram.data}
                        type="Pie"
                        options={pieChart.options}
                      />
                      */
                    }
                      <Pie  
                       data={tabDiagramEtatVehicule} 
                       width={10}
                       height={50} 
                       
                       />
                    
                    <CardFooter stats className={classesChartList.cardFooter}>
                      <h6 className={classesChartList.legendTitle}>Legend</h6>
                      <i className={"fas fa-circle " + classesChartList.success} /> En marche{` `}
                      <i className={"fas fa-circle " + classesChartList.warning} /> Eteint
                      {` `}
                      <i className={"fas fa-circle " + classesChartList.danger} /> Traceur non connecté
                      {` `}
                    </CardFooter>
                  



          </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>




      <GridContainer>
        
        
      </GridContainer>
    </div>
  );
}


export default DashboardUser;